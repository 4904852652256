.plans{
    display: flex;
    flex-direction: column;
    position: relative;
    /* gap: 2rem; */
}
.header-p{
    display: flex;
    gap: 4rem;
    padding: 0 3rem;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;
}
.Plans-cards{
    margin-top: 4rem;
    display: flex;
    gap: 3rem;
   align-items: center;
   justify-content: center;
}
.plan{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
    background: var(--caloryCard);
    color: white;
    width: 15rem;
    height: 27rem;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);  
}
.plan:nth-child(2)>svg{
    fill: white;
}
.plan:nth-child(2)>button{
    color: var(--orange);
}
.plan>div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;  
    text-transform: uppercase;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>:nth-child(1){
    width: 1rem;
    height: 1rem;
}
.feature>:nth-child(2){
    color: white;
    font-size: 1rem;
}
.benefits{
    color: white;
    font-size: 0.8rem;
}
.plan-blur-l{
    width: 32rem;
    height: 23rem;
    left: 0;
}
.plan-blur-r{
    width: 32rem;
    height: 23rem;
    right: 2rem;
}