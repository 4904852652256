.Reasons{
    padding: 0 2rem;
    display: flex;
    gap: 1rem;
}
.left-r{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r>img{
    /* width: 12rem; */
    object-fit: cover;
    /* padding: 0.5rem; */
}
.left-r> :nth-child(1){
    width: 12rem;
    height: 28rem;
    grid-row: 1/3;
}
.left-r > :nth-child(2){
    /* position: absolute; */
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.left-r > :nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    height: 11rem;
}.left-r > :nth-child(4){
    width: 10rem;
    grid-column: 3/4;
    grid-row: 2;
    height: 11rem;
}
.right-r{
    flex: 1 1;
    display: flex;
    flex-direction: column;
}
.Reason-text{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;   
}
.Reason-text>span{
    color: var(--orange);
    font-size: 1rem;
    font-weight: bold;
}
.Reason-text>div{
    font-size: 3rem;
    font-weight: bold;
    color: white;
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: white;
    text-transform: uppercase;
    padding-top: 1rem;
}
.details-r>div{
    display: flex;
    gap: 1rem;
    font-size: 1rem;
}
.details-r>div>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
}
.partners>span{
    color: var(--gray);
    font-weight: normal;
}
.partners>div{
    display: flex;
    gap: 1rem;
}
.partners>div>img{
    width: 2.5rem;
}
