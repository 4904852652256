.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;    
}
.left-t{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 2rem;
    color: white;
    text-transform: uppercase;
}
.left-t>span:nth-child(1){
    color: var(--orange);
    font-weight: bold;  
}
.left-t>span:nth-child(2){
    font-size: 3rem;
    font-weight: bold;
}
.left-t>span:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.left-t>span:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}
.right-t{
    flex: 1;
    position: relative;
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    right: 9rem;
    top: 0.9rem;
    border: 2px solid var(--orange);
    background-color: transparent;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background: var(--planCard);
}
.arrows{
    position: absolute;
    top: 21rem;
    right: 30rem;
    display: flex;
    gap: 1rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}